import { useValidationResults } from './useValidationResults.ts';
import { useValidationResourceTypes } from './validationResourceTypes.ts';

export const samplesPerResourceType = 2;

export function useValidationProgress() {
  const results = useValidationResults();
  const validationResourceTypes = useValidationResourceTypes();

  const samplesChecked = validationResourceTypes.reduce((total, { resourceType }) => {
    const r = results.filter((result) => result.resourceType === resourceType);
    return total + Math.min(samplesPerResourceType, r.length);
  }, 0);

  const samplesTotal = samplesPerResourceType * validationResourceTypes.length;

  return {
    hasFinished: samplesChecked === samplesTotal,
    hasStarted: samplesChecked > 0,
    samplesRemaining: samplesTotal - samplesChecked,
    samplesTotal,
    samplesChecked,
  };
}

import { Button, Card, Icons, Stack, Typography } from '@healthinal/ui';
import { createFileRoute } from '@tanstack/react-router';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'usehooks-ts';
import { DataValidationResultStatus } from '../../../api/generated.ts';
import { ContentStack } from '../../../common/components/ContentStack.tsx';
import { GridLayout } from '../../../common/components/GridLayout.tsx';
import { LinkButton } from '../../../common/components/LinkButton.tsx';
import { OverviewTitle } from '../../../common/components/OverviewTitle.tsx';
import { ResourceIcon } from '../../../common/components/ResourceIcon.tsx';
import { ValidationCardContent } from '../../../data-validation/common/ValidationCardContent.tsx';
import { ValidationProgressHeader } from '../../../data-validation/common/ValidationProgressHeader.tsx';
import { ValidationResourceName } from '../../../data-validation/common/ValidationResourceName.tsx';
import { ValidationSampleStatus } from '../../../data-validation/common/ValidationSampleStatus.tsx';
import { ReportedDiscrepancies } from '../../../data-validation/overview/ReportedDiscrepancies.tsx';
import { ValidationInstructions } from '../../../data-validation/overview/ValidationInstructions.tsx';
import { useValidationProgress } from '../../../data-validation/useValidationProgress.ts';
import { useValidationResults } from '../../../data-validation/useValidationResults.ts';
import { useValidationResourceTypes } from '../../../data-validation/validationResourceTypes.ts';
import { theme } from '../../../theme.ts';

export const Route = createFileRoute('/_main/data-validation/')({
  component: DataValidationOverview,
});

function DataValidationOverview() {
  const { t } = useTranslation();
  const discrepancies = useValidationResults().filter(
    (result) => result.status === DataValidationResultStatus.DISCREPANCY_REPORTED,
  );
  const { hasFinished, hasStarted, samplesRemaining } = useValidationProgress();
  const [showInstructions, toggleInstructions] = useToggle(!hasStarted);
  const validationResourceTypes = useValidationResourceTypes();

  const translationContext = hasFinished ? 'finished' : hasStarted ? 'started' : undefined;

  return (
    <>
      <ContentStack gap={5}>
        <ValidationProgressHeader
          title={hasFinished ? t('data-validation.finished.title') : t('data-validation.overview.title')}
          body={
            hasFinished ? t('data-validation.samples-remaining', { count: 0 }) : t('data-validation.overview.intro')
          }
        />

        <GridLayout columns={3}>
          {validationResourceTypes.map(({ resourceType }) => (
            <Card key={resourceType} variant="soft" sx={{ background: theme.vars.palette.background.level1 }}>
              <ValidationCardContent
                icon={<ResourceIcon type={resourceType} />}
                title={<ValidationResourceName resourceType={resourceType} />}>
                <ValidationSampleStatus resourceType={resourceType} />
              </ValidationCardContent>
            </Card>
          ))}
        </GridLayout>

        <Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={1}>
            <OverviewTitle>{t('data-validation.instructions.title', { context: translationContext })}</OverviewTitle>
            <Button
              variant="plain"
              onClick={toggleInstructions}
              endDecorator={showInstructions ? <Icons.ExpandLess /> : <Icons.ExpandMore />}>
              {showInstructions ? t('data-validation.instructions.hide') : t('data-validation.instructions.show')}
            </Button>
          </Stack>

          <AnimatePresence initial={false}>{showInstructions && <ValidationInstructions />}</AnimatePresence>

          <Card sx={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', paddingLeft: 3 }}>
            <Typography textColor="text.tertiary">
              {hasFinished
                ? t('data-validation.finished.thank-you')
                : hasStarted
                  ? t('data-validation.samples-remaining', { count: samplesRemaining })
                  : t('data-validation.overview.time-commitment')}
            </Typography>
            <LinkButton
              to="/data-validation/patient"
              endDecorator={<Icons.ChevronRight />}
              variant={hasFinished ? 'soft' : 'solid'}>
              {t('data-validation.overview.start-validation', { context: translationContext })}
            </LinkButton>
          </Card>
        </Stack>

        {discrepancies.length > 0 && (
          <Stack gap={2}>
            <OverviewTitle>{t('data-validation.discrepancies.reported-discrepancies')}</OverviewTitle>
            <ReportedDiscrepancies results={discrepancies} />
          </Stack>
        )}
      </ContentStack>
    </>
  );
}

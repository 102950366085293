import { Icons, Stack, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { useWhoamiSuspense } from '../api/generated.ts';
import { LinkButton } from '../common/components/LinkButton.tsx';
import { heurekaBlue, theme } from '../theme.ts';

export function Welcome() {
  const { data: whoami } = useWhoamiSuspense();
  const { t } = useTranslation();
  return (
    <Stack
      alignItems="center"
      padding={7}
      sx={{
        background: theme.vars.palette.background.level1,
        borderRadius: theme.vars.radius.xl,
      }}>
      <Stack alignItems="center" maxWidth={560} gap={4}>
        <Stack alignItems="center" gap={2}>
          <Typography level="title-lg" color="primary">
            {t('welcome.greeting', { user: whoami.name })}
          </Typography>
          <Typography level="h3" textColor={heurekaBlue}>
            {t('welcome.title')}
          </Typography>
        </Stack>
        <Typography textAlign="center" textColor="text.tertiary">
          {t('welcome.text')}
        </Typography>
        <Typography textAlign="center" textColor="text.tertiary">
          {t('welcome.next-step')}
        </Typography>
        <LinkButton to="/apps" endDecorator={<Icons.ChevronRight />}>
          {t('discover-data-projects')}
        </LinkButton>
      </Stack>
    </Stack>
  );
}

import { Card, Icons, Stack, Typography } from '@healthinal/ui';
import { createColumnHelper, IdentifiedColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ObservationDto } from '../../api/generated.ts';
import { DataTable } from '../../common/components/DataTable.tsx';
import { OverviewTitle } from '../../common/components/OverviewTitle.tsx';
import { TabularNums } from '../../common/components/TabularNums.tsx';
import { formatDate } from '../../i18n/date.ts';
import { usePatientData } from '../usePatientData.tsx';

interface ObservationsViewProps {
  pid: string;
}

const columnHelper = createColumnHelper<ObservationDto>();

export function ObservationsView({ pid }: ObservationsViewProps) {
  const { t } = useTranslation();
  const { data } = usePatientData(pid);
  const vitalObservations = data.observations.filter((obs) =>
    obs.category?.some((category) => category.coding?.some((coding) => coding.code === 'vital-signs')),
  );
  const laboratoryObservations = data.observations.filter((obs) =>
    obs.category?.some((category) => category.coding?.some((coding) => coding.code === 'laboratory')),
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor((observation) => observation.code?.coding.map((coding) => coding.code).join(', '), {
        id: 'code',
        header: t('data-validation.observations.code'),
        meta: {
          width: 88,
        },
      }),
      columnHelper.accessor(
        (observation) => [
          observation.code?.text ?? observation.code?.coding.map((coding) => coding.display).join(', '),
          observation.note.map((note) => note.text).join('; '),
        ],
        {
          id: 'observation',
          header: t('data-validation.observations.observation'),
          cell: ({ getValue }) => {
            const [codeText, note] = getValue();
            return (
              <Stack direction="row" columnGap={2} flexWrap="wrap">
                {codeText && <Typography>{codeText}</Typography>}
                {note && codeText !== note && (
                  <Typography textColor="text.tertiary" startDecorator={<Icons.StickyNote2Outlined />}>
                    {note}
                  </Typography>
                )}
              </Stack>
            );
          },
        },
      ),
      columnHelper.accessor((observation) => observation.valueQuantity?.value ?? undefined, {
        id: 'value',
        header: t('data-validation.observations.value'),
        meta: {
          width: 120,
        },
      }),
      columnHelper.accessor((observation) => observation.valueQuantity?.unit ?? undefined, {
        id: 'unit',
        header: t('data-validation.observations.unit'),
        meta: {
          width: 120,
        },
      }),
      columnHelper.accessor((observation) => observation.effectiveDateTime ?? undefined, {
        id: 'effective-date',
        header: t('data-validation.observations.date'),
        ...dateColumn,
      }),
    ],
    [t],
  );

  return (
    <Stack gap={4}>
      <Stack gap={2}>
        <OverviewTitle>{t('data-validation.observations.vital-signs')}</OverviewTitle>
        <Card sx={{ padding: 0 }}>
          {vitalObservations.length > 0 ? (
            <DataTable
              columns={columns}
              initialState={{ sorting: [{ id: 'effective-date', desc: true }] }}
              enableSortingRemoval={false}
              data={vitalObservations}
            />
          ) : (
            <Typography textAlign="center" padding={2}>
              {t('data-validation.observations.no-data')}
            </Typography>
          )}
        </Card>
      </Stack>
      <Stack gap={2}>
        <OverviewTitle>{t('data-validation.observations.laboratory')}</OverviewTitle>
        <Card sx={{ padding: 0 }}>
          {laboratoryObservations.length > 0 ? (
            <DataTable
              columns={columns}
              initialState={{ sorting: [{ id: 'effective-date', desc: true }] }}
              enableSortingRemoval={false}
              data={laboratoryObservations}
            />
          ) : (
            <Typography textAlign="center" padding={2}>
              {t('data-validation.observations.no-data')}
            </Typography>
          )}
        </Card>
      </Stack>
    </Stack>
  );
}

const dateColumn: IdentifiedColumnDef<ObservationDto, string | undefined> = {
  cell: ({ getValue }) => <TabularNums>{formatDate(getValue())}</TabularNums>,
  meta: {
    width: 120,
  },
};

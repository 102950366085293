/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './__root'
import { Route as UnknownUserImport } from './unknown-user'
import { Route as LoginErrorImport } from './login-error'
import { Route as ContinueImport } from './continue'
import { Route as AuthorizationImport } from './authorization'
import { Route as MainImport } from './_main'
import { Route as MainIndexImport } from './_main/index'
import { Route as AuthorizationUpdateImport } from './authorization/update'
import { Route as AuthorizationSuccessfulRevokeImport } from './authorization/successful-revoke'
import { Route as AuthorizationRevokeImport } from './authorization/revoke'
import { Route as AuthorizationInvalidRedirectUriImport } from './authorization/invalid-redirect-uri'
import { Route as AuthorizationGrantImport } from './authorization/grant'
import { Route as MainDataValidationImport } from './_main/data-validation'
import { Route as MainSettingsIndexImport } from './_main/settings/index'
import { Route as MainDataValidationIndexImport } from './_main/data-validation/index'
import { Route as MainAppsIndexImport } from './_main/apps/index'
import { Route as MainAccessLogIndexImport } from './_main/access-log/index'
import { Route as MainSettingsPidblocklistImport } from './_main/settings/pidblocklist'
import { Route as MainAppsIdImport } from './_main/apps/$id'
import { Route as MainAppsIdIndexImport } from './_main/apps/$id/index'
import { Route as MainDataValidationPatientSearchImport } from './_main/data-validation/patient/_search'
import { Route as MainAppsIdInfoImport } from './_main/apps/$id/info'
import { Route as MainAppsIdControlCenterImport } from './_main/apps/$id/control-center'
import { Route as MainDataValidationPatientSearchIndexImport } from './_main/data-validation/patient/_search.index'
import { Route as MainDataValidationPatientSearchPidImport } from './_main/data-validation/patient/_search.$pid'
import { Route as MainDataValidationPatientPidSubmittedImport } from './_main/data-validation/patient/$pid/submitted'
import { Route as MainDataValidationPatientPidPatientImport } from './_main/data-validation/patient/$pid/patient'
import { Route as MainDataValidationPatientPidObservationsImport } from './_main/data-validation/patient/$pid/observations'
import { Route as MainDataValidationPatientPidMedicationImport } from './_main/data-validation/patient/$pid/medication'
import { Route as MainDataValidationPatientPidConditionsImport } from './_main/data-validation/patient/$pid/conditions'

// Create Virtual Routes

const MainDataValidationPatientImport = createFileRoute(
  '/_main/data-validation/patient',
)()

// Create/Update Routes

const UnknownUserRoute = UnknownUserImport.update({
  id: '/unknown-user',
  path: '/unknown-user',
  getParentRoute: () => rootRoute,
} as any)

const LoginErrorRoute = LoginErrorImport.update({
  id: '/login-error',
  path: '/login-error',
  getParentRoute: () => rootRoute,
} as any)

const ContinueRoute = ContinueImport.update({
  id: '/continue',
  path: '/continue',
  getParentRoute: () => rootRoute,
} as any)

const AuthorizationRoute = AuthorizationImport.update({
  id: '/authorization',
  path: '/authorization',
  getParentRoute: () => rootRoute,
} as any)

const MainRoute = MainImport.update({
  id: '/_main',
  getParentRoute: () => rootRoute,
} as any)

const MainIndexRoute = MainIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => MainRoute,
} as any)

const AuthorizationUpdateRoute = AuthorizationUpdateImport.update({
  id: '/update',
  path: '/update',
  getParentRoute: () => AuthorizationRoute,
} as any)

const AuthorizationSuccessfulRevokeRoute =
  AuthorizationSuccessfulRevokeImport.update({
    id: '/successful-revoke',
    path: '/successful-revoke',
    getParentRoute: () => AuthorizationRoute,
  } as any)

const AuthorizationRevokeRoute = AuthorizationRevokeImport.update({
  id: '/revoke',
  path: '/revoke',
  getParentRoute: () => AuthorizationRoute,
} as any)

const AuthorizationInvalidRedirectUriRoute =
  AuthorizationInvalidRedirectUriImport.update({
    id: '/invalid-redirect-uri',
    path: '/invalid-redirect-uri',
    getParentRoute: () => AuthorizationRoute,
  } as any)

const AuthorizationGrantRoute = AuthorizationGrantImport.update({
  id: '/grant',
  path: '/grant',
  getParentRoute: () => AuthorizationRoute,
} as any)

const MainDataValidationRoute = MainDataValidationImport.update({
  id: '/data-validation',
  path: '/data-validation',
  getParentRoute: () => MainRoute,
} as any)

const MainDataValidationPatientRoute = MainDataValidationPatientImport.update({
  id: '/patient',
  path: '/patient',
  getParentRoute: () => MainDataValidationRoute,
} as any)

const MainSettingsIndexRoute = MainSettingsIndexImport.update({
  id: '/settings/',
  path: '/settings/',
  getParentRoute: () => MainRoute,
} as any)

const MainDataValidationIndexRoute = MainDataValidationIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => MainDataValidationRoute,
} as any)

const MainAppsIndexRoute = MainAppsIndexImport.update({
  id: '/apps/',
  path: '/apps/',
  getParentRoute: () => MainRoute,
} as any)

const MainAccessLogIndexRoute = MainAccessLogIndexImport.update({
  id: '/access-log/',
  path: '/access-log/',
  getParentRoute: () => MainRoute,
} as any)

const MainSettingsPidblocklistRoute = MainSettingsPidblocklistImport.update({
  id: '/settings/pidblocklist',
  path: '/settings/pidblocklist',
  getParentRoute: () => MainRoute,
} as any)

const MainAppsIdRoute = MainAppsIdImport.update({
  id: '/apps/$id',
  path: '/apps/$id',
  getParentRoute: () => MainRoute,
} as any)

const MainAppsIdIndexRoute = MainAppsIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => MainAppsIdRoute,
} as any)

const MainDataValidationPatientSearchRoute =
  MainDataValidationPatientSearchImport.update({
    id: '/_search',
    getParentRoute: () => MainDataValidationPatientRoute,
  } as any)

const MainAppsIdInfoRoute = MainAppsIdInfoImport.update({
  id: '/info',
  path: '/info',
  getParentRoute: () => MainAppsIdRoute,
} as any)

const MainAppsIdControlCenterRoute = MainAppsIdControlCenterImport.update({
  id: '/control-center',
  path: '/control-center',
  getParentRoute: () => MainAppsIdRoute,
} as any)

const MainDataValidationPatientSearchIndexRoute =
  MainDataValidationPatientSearchIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => MainDataValidationPatientSearchRoute,
  } as any)

const MainDataValidationPatientSearchPidRoute =
  MainDataValidationPatientSearchPidImport.update({
    id: '/$pid',
    path: '/$pid',
    getParentRoute: () => MainDataValidationPatientSearchRoute,
  } as any)

const MainDataValidationPatientPidSubmittedRoute =
  MainDataValidationPatientPidSubmittedImport.update({
    id: '/$pid/submitted',
    path: '/$pid/submitted',
    getParentRoute: () => MainDataValidationPatientRoute,
  } as any)

const MainDataValidationPatientPidPatientRoute =
  MainDataValidationPatientPidPatientImport.update({
    id: '/$pid/patient',
    path: '/$pid/patient',
    getParentRoute: () => MainDataValidationPatientRoute,
  } as any)

const MainDataValidationPatientPidObservationsRoute =
  MainDataValidationPatientPidObservationsImport.update({
    id: '/$pid/observations',
    path: '/$pid/observations',
    getParentRoute: () => MainDataValidationPatientRoute,
  } as any)

const MainDataValidationPatientPidMedicationRoute =
  MainDataValidationPatientPidMedicationImport.update({
    id: '/$pid/medication',
    path: '/$pid/medication',
    getParentRoute: () => MainDataValidationPatientRoute,
  } as any)

const MainDataValidationPatientPidConditionsRoute =
  MainDataValidationPatientPidConditionsImport.update({
    id: '/$pid/conditions',
    path: '/$pid/conditions',
    getParentRoute: () => MainDataValidationPatientRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_main': {
      id: '/_main'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof MainImport
      parentRoute: typeof rootRoute
    }
    '/authorization': {
      id: '/authorization'
      path: '/authorization'
      fullPath: '/authorization'
      preLoaderRoute: typeof AuthorizationImport
      parentRoute: typeof rootRoute
    }
    '/continue': {
      id: '/continue'
      path: '/continue'
      fullPath: '/continue'
      preLoaderRoute: typeof ContinueImport
      parentRoute: typeof rootRoute
    }
    '/login-error': {
      id: '/login-error'
      path: '/login-error'
      fullPath: '/login-error'
      preLoaderRoute: typeof LoginErrorImport
      parentRoute: typeof rootRoute
    }
    '/unknown-user': {
      id: '/unknown-user'
      path: '/unknown-user'
      fullPath: '/unknown-user'
      preLoaderRoute: typeof UnknownUserImport
      parentRoute: typeof rootRoute
    }
    '/_main/data-validation': {
      id: '/_main/data-validation'
      path: '/data-validation'
      fullPath: '/data-validation'
      preLoaderRoute: typeof MainDataValidationImport
      parentRoute: typeof MainImport
    }
    '/authorization/grant': {
      id: '/authorization/grant'
      path: '/grant'
      fullPath: '/authorization/grant'
      preLoaderRoute: typeof AuthorizationGrantImport
      parentRoute: typeof AuthorizationImport
    }
    '/authorization/invalid-redirect-uri': {
      id: '/authorization/invalid-redirect-uri'
      path: '/invalid-redirect-uri'
      fullPath: '/authorization/invalid-redirect-uri'
      preLoaderRoute: typeof AuthorizationInvalidRedirectUriImport
      parentRoute: typeof AuthorizationImport
    }
    '/authorization/revoke': {
      id: '/authorization/revoke'
      path: '/revoke'
      fullPath: '/authorization/revoke'
      preLoaderRoute: typeof AuthorizationRevokeImport
      parentRoute: typeof AuthorizationImport
    }
    '/authorization/successful-revoke': {
      id: '/authorization/successful-revoke'
      path: '/successful-revoke'
      fullPath: '/authorization/successful-revoke'
      preLoaderRoute: typeof AuthorizationSuccessfulRevokeImport
      parentRoute: typeof AuthorizationImport
    }
    '/authorization/update': {
      id: '/authorization/update'
      path: '/update'
      fullPath: '/authorization/update'
      preLoaderRoute: typeof AuthorizationUpdateImport
      parentRoute: typeof AuthorizationImport
    }
    '/_main/': {
      id: '/_main/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof MainIndexImport
      parentRoute: typeof MainImport
    }
    '/_main/apps/$id': {
      id: '/_main/apps/$id'
      path: '/apps/$id'
      fullPath: '/apps/$id'
      preLoaderRoute: typeof MainAppsIdImport
      parentRoute: typeof MainImport
    }
    '/_main/settings/pidblocklist': {
      id: '/_main/settings/pidblocklist'
      path: '/settings/pidblocklist'
      fullPath: '/settings/pidblocklist'
      preLoaderRoute: typeof MainSettingsPidblocklistImport
      parentRoute: typeof MainImport
    }
    '/_main/access-log/': {
      id: '/_main/access-log/'
      path: '/access-log'
      fullPath: '/access-log'
      preLoaderRoute: typeof MainAccessLogIndexImport
      parentRoute: typeof MainImport
    }
    '/_main/apps/': {
      id: '/_main/apps/'
      path: '/apps'
      fullPath: '/apps'
      preLoaderRoute: typeof MainAppsIndexImport
      parentRoute: typeof MainImport
    }
    '/_main/data-validation/': {
      id: '/_main/data-validation/'
      path: '/'
      fullPath: '/data-validation/'
      preLoaderRoute: typeof MainDataValidationIndexImport
      parentRoute: typeof MainDataValidationImport
    }
    '/_main/settings/': {
      id: '/_main/settings/'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof MainSettingsIndexImport
      parentRoute: typeof MainImport
    }
    '/_main/apps/$id/control-center': {
      id: '/_main/apps/$id/control-center'
      path: '/control-center'
      fullPath: '/apps/$id/control-center'
      preLoaderRoute: typeof MainAppsIdControlCenterImport
      parentRoute: typeof MainAppsIdImport
    }
    '/_main/apps/$id/info': {
      id: '/_main/apps/$id/info'
      path: '/info'
      fullPath: '/apps/$id/info'
      preLoaderRoute: typeof MainAppsIdInfoImport
      parentRoute: typeof MainAppsIdImport
    }
    '/_main/data-validation/patient': {
      id: '/_main/data-validation/patient'
      path: '/patient'
      fullPath: '/data-validation/patient'
      preLoaderRoute: typeof MainDataValidationPatientImport
      parentRoute: typeof MainDataValidationImport
    }
    '/_main/data-validation/patient/_search': {
      id: '/_main/data-validation/patient/_search'
      path: '/patient'
      fullPath: '/data-validation/patient'
      preLoaderRoute: typeof MainDataValidationPatientSearchImport
      parentRoute: typeof MainDataValidationPatientRoute
    }
    '/_main/apps/$id/': {
      id: '/_main/apps/$id/'
      path: '/'
      fullPath: '/apps/$id/'
      preLoaderRoute: typeof MainAppsIdIndexImport
      parentRoute: typeof MainAppsIdImport
    }
    '/_main/data-validation/patient/$pid/conditions': {
      id: '/_main/data-validation/patient/$pid/conditions'
      path: '/$pid/conditions'
      fullPath: '/data-validation/patient/$pid/conditions'
      preLoaderRoute: typeof MainDataValidationPatientPidConditionsImport
      parentRoute: typeof MainDataValidationPatientImport
    }
    '/_main/data-validation/patient/$pid/medication': {
      id: '/_main/data-validation/patient/$pid/medication'
      path: '/$pid/medication'
      fullPath: '/data-validation/patient/$pid/medication'
      preLoaderRoute: typeof MainDataValidationPatientPidMedicationImport
      parentRoute: typeof MainDataValidationPatientImport
    }
    '/_main/data-validation/patient/$pid/observations': {
      id: '/_main/data-validation/patient/$pid/observations'
      path: '/$pid/observations'
      fullPath: '/data-validation/patient/$pid/observations'
      preLoaderRoute: typeof MainDataValidationPatientPidObservationsImport
      parentRoute: typeof MainDataValidationPatientImport
    }
    '/_main/data-validation/patient/$pid/patient': {
      id: '/_main/data-validation/patient/$pid/patient'
      path: '/$pid/patient'
      fullPath: '/data-validation/patient/$pid/patient'
      preLoaderRoute: typeof MainDataValidationPatientPidPatientImport
      parentRoute: typeof MainDataValidationPatientImport
    }
    '/_main/data-validation/patient/$pid/submitted': {
      id: '/_main/data-validation/patient/$pid/submitted'
      path: '/$pid/submitted'
      fullPath: '/data-validation/patient/$pid/submitted'
      preLoaderRoute: typeof MainDataValidationPatientPidSubmittedImport
      parentRoute: typeof MainDataValidationPatientImport
    }
    '/_main/data-validation/patient/_search/$pid': {
      id: '/_main/data-validation/patient/_search/$pid'
      path: '/$pid'
      fullPath: '/data-validation/patient/$pid'
      preLoaderRoute: typeof MainDataValidationPatientSearchPidImport
      parentRoute: typeof MainDataValidationPatientSearchImport
    }
    '/_main/data-validation/patient/_search/': {
      id: '/_main/data-validation/patient/_search/'
      path: '/'
      fullPath: '/data-validation/patient/'
      preLoaderRoute: typeof MainDataValidationPatientSearchIndexImport
      parentRoute: typeof MainDataValidationPatientSearchImport
    }
  }
}

// Create and export the route tree

interface MainDataValidationPatientSearchRouteChildren {
  MainDataValidationPatientSearchPidRoute: typeof MainDataValidationPatientSearchPidRoute
  MainDataValidationPatientSearchIndexRoute: typeof MainDataValidationPatientSearchIndexRoute
}

const MainDataValidationPatientSearchRouteChildren: MainDataValidationPatientSearchRouteChildren =
  {
    MainDataValidationPatientSearchPidRoute:
      MainDataValidationPatientSearchPidRoute,
    MainDataValidationPatientSearchIndexRoute:
      MainDataValidationPatientSearchIndexRoute,
  }

const MainDataValidationPatientSearchRouteWithChildren =
  MainDataValidationPatientSearchRoute._addFileChildren(
    MainDataValidationPatientSearchRouteChildren,
  )

interface MainDataValidationPatientRouteChildren {
  MainDataValidationPatientSearchRoute: typeof MainDataValidationPatientSearchRouteWithChildren
  MainDataValidationPatientPidConditionsRoute: typeof MainDataValidationPatientPidConditionsRoute
  MainDataValidationPatientPidMedicationRoute: typeof MainDataValidationPatientPidMedicationRoute
  MainDataValidationPatientPidObservationsRoute: typeof MainDataValidationPatientPidObservationsRoute
  MainDataValidationPatientPidPatientRoute: typeof MainDataValidationPatientPidPatientRoute
  MainDataValidationPatientPidSubmittedRoute: typeof MainDataValidationPatientPidSubmittedRoute
}

const MainDataValidationPatientRouteChildren: MainDataValidationPatientRouteChildren =
  {
    MainDataValidationPatientSearchRoute:
      MainDataValidationPatientSearchRouteWithChildren,
    MainDataValidationPatientPidConditionsRoute:
      MainDataValidationPatientPidConditionsRoute,
    MainDataValidationPatientPidMedicationRoute:
      MainDataValidationPatientPidMedicationRoute,
    MainDataValidationPatientPidObservationsRoute:
      MainDataValidationPatientPidObservationsRoute,
    MainDataValidationPatientPidPatientRoute:
      MainDataValidationPatientPidPatientRoute,
    MainDataValidationPatientPidSubmittedRoute:
      MainDataValidationPatientPidSubmittedRoute,
  }

const MainDataValidationPatientRouteWithChildren =
  MainDataValidationPatientRoute._addFileChildren(
    MainDataValidationPatientRouteChildren,
  )

interface MainDataValidationRouteChildren {
  MainDataValidationIndexRoute: typeof MainDataValidationIndexRoute
  MainDataValidationPatientRoute: typeof MainDataValidationPatientRouteWithChildren
}

const MainDataValidationRouteChildren: MainDataValidationRouteChildren = {
  MainDataValidationIndexRoute: MainDataValidationIndexRoute,
  MainDataValidationPatientRoute: MainDataValidationPatientRouteWithChildren,
}

const MainDataValidationRouteWithChildren =
  MainDataValidationRoute._addFileChildren(MainDataValidationRouteChildren)

interface MainAppsIdRouteChildren {
  MainAppsIdControlCenterRoute: typeof MainAppsIdControlCenterRoute
  MainAppsIdInfoRoute: typeof MainAppsIdInfoRoute
  MainAppsIdIndexRoute: typeof MainAppsIdIndexRoute
}

const MainAppsIdRouteChildren: MainAppsIdRouteChildren = {
  MainAppsIdControlCenterRoute: MainAppsIdControlCenterRoute,
  MainAppsIdInfoRoute: MainAppsIdInfoRoute,
  MainAppsIdIndexRoute: MainAppsIdIndexRoute,
}

const MainAppsIdRouteWithChildren = MainAppsIdRoute._addFileChildren(
  MainAppsIdRouteChildren,
)

interface MainRouteChildren {
  MainDataValidationRoute: typeof MainDataValidationRouteWithChildren
  MainIndexRoute: typeof MainIndexRoute
  MainAppsIdRoute: typeof MainAppsIdRouteWithChildren
  MainSettingsPidblocklistRoute: typeof MainSettingsPidblocklistRoute
  MainAccessLogIndexRoute: typeof MainAccessLogIndexRoute
  MainAppsIndexRoute: typeof MainAppsIndexRoute
  MainSettingsIndexRoute: typeof MainSettingsIndexRoute
}

const MainRouteChildren: MainRouteChildren = {
  MainDataValidationRoute: MainDataValidationRouteWithChildren,
  MainIndexRoute: MainIndexRoute,
  MainAppsIdRoute: MainAppsIdRouteWithChildren,
  MainSettingsPidblocklistRoute: MainSettingsPidblocklistRoute,
  MainAccessLogIndexRoute: MainAccessLogIndexRoute,
  MainAppsIndexRoute: MainAppsIndexRoute,
  MainSettingsIndexRoute: MainSettingsIndexRoute,
}

const MainRouteWithChildren = MainRoute._addFileChildren(MainRouteChildren)

interface AuthorizationRouteChildren {
  AuthorizationGrantRoute: typeof AuthorizationGrantRoute
  AuthorizationInvalidRedirectUriRoute: typeof AuthorizationInvalidRedirectUriRoute
  AuthorizationRevokeRoute: typeof AuthorizationRevokeRoute
  AuthorizationSuccessfulRevokeRoute: typeof AuthorizationSuccessfulRevokeRoute
  AuthorizationUpdateRoute: typeof AuthorizationUpdateRoute
}

const AuthorizationRouteChildren: AuthorizationRouteChildren = {
  AuthorizationGrantRoute: AuthorizationGrantRoute,
  AuthorizationInvalidRedirectUriRoute: AuthorizationInvalidRedirectUriRoute,
  AuthorizationRevokeRoute: AuthorizationRevokeRoute,
  AuthorizationSuccessfulRevokeRoute: AuthorizationSuccessfulRevokeRoute,
  AuthorizationUpdateRoute: AuthorizationUpdateRoute,
}

const AuthorizationRouteWithChildren = AuthorizationRoute._addFileChildren(
  AuthorizationRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof MainRouteWithChildren
  '/authorization': typeof AuthorizationRouteWithChildren
  '/continue': typeof ContinueRoute
  '/login-error': typeof LoginErrorRoute
  '/unknown-user': typeof UnknownUserRoute
  '/data-validation': typeof MainDataValidationRouteWithChildren
  '/authorization/grant': typeof AuthorizationGrantRoute
  '/authorization/invalid-redirect-uri': typeof AuthorizationInvalidRedirectUriRoute
  '/authorization/revoke': typeof AuthorizationRevokeRoute
  '/authorization/successful-revoke': typeof AuthorizationSuccessfulRevokeRoute
  '/authorization/update': typeof AuthorizationUpdateRoute
  '/': typeof MainIndexRoute
  '/apps/$id': typeof MainAppsIdRouteWithChildren
  '/settings/pidblocklist': typeof MainSettingsPidblocklistRoute
  '/access-log': typeof MainAccessLogIndexRoute
  '/apps': typeof MainAppsIndexRoute
  '/data-validation/': typeof MainDataValidationIndexRoute
  '/settings': typeof MainSettingsIndexRoute
  '/apps/$id/control-center': typeof MainAppsIdControlCenterRoute
  '/apps/$id/info': typeof MainAppsIdInfoRoute
  '/data-validation/patient': typeof MainDataValidationPatientSearchRouteWithChildren
  '/apps/$id/': typeof MainAppsIdIndexRoute
  '/data-validation/patient/$pid/conditions': typeof MainDataValidationPatientPidConditionsRoute
  '/data-validation/patient/$pid/medication': typeof MainDataValidationPatientPidMedicationRoute
  '/data-validation/patient/$pid/observations': typeof MainDataValidationPatientPidObservationsRoute
  '/data-validation/patient/$pid/patient': typeof MainDataValidationPatientPidPatientRoute
  '/data-validation/patient/$pid/submitted': typeof MainDataValidationPatientPidSubmittedRoute
  '/data-validation/patient/$pid': typeof MainDataValidationPatientSearchPidRoute
  '/data-validation/patient/': typeof MainDataValidationPatientSearchIndexRoute
}

export interface FileRoutesByTo {
  '/authorization': typeof AuthorizationRouteWithChildren
  '/continue': typeof ContinueRoute
  '/login-error': typeof LoginErrorRoute
  '/unknown-user': typeof UnknownUserRoute
  '/authorization/grant': typeof AuthorizationGrantRoute
  '/authorization/invalid-redirect-uri': typeof AuthorizationInvalidRedirectUriRoute
  '/authorization/revoke': typeof AuthorizationRevokeRoute
  '/authorization/successful-revoke': typeof AuthorizationSuccessfulRevokeRoute
  '/authorization/update': typeof AuthorizationUpdateRoute
  '/': typeof MainIndexRoute
  '/settings/pidblocklist': typeof MainSettingsPidblocklistRoute
  '/access-log': typeof MainAccessLogIndexRoute
  '/apps': typeof MainAppsIndexRoute
  '/data-validation': typeof MainDataValidationIndexRoute
  '/settings': typeof MainSettingsIndexRoute
  '/apps/$id/control-center': typeof MainAppsIdControlCenterRoute
  '/apps/$id/info': typeof MainAppsIdInfoRoute
  '/data-validation/patient': typeof MainDataValidationPatientSearchIndexRoute
  '/apps/$id': typeof MainAppsIdIndexRoute
  '/data-validation/patient/$pid/conditions': typeof MainDataValidationPatientPidConditionsRoute
  '/data-validation/patient/$pid/medication': typeof MainDataValidationPatientPidMedicationRoute
  '/data-validation/patient/$pid/observations': typeof MainDataValidationPatientPidObservationsRoute
  '/data-validation/patient/$pid/patient': typeof MainDataValidationPatientPidPatientRoute
  '/data-validation/patient/$pid/submitted': typeof MainDataValidationPatientPidSubmittedRoute
  '/data-validation/patient/$pid': typeof MainDataValidationPatientSearchPidRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_main': typeof MainRouteWithChildren
  '/authorization': typeof AuthorizationRouteWithChildren
  '/continue': typeof ContinueRoute
  '/login-error': typeof LoginErrorRoute
  '/unknown-user': typeof UnknownUserRoute
  '/_main/data-validation': typeof MainDataValidationRouteWithChildren
  '/authorization/grant': typeof AuthorizationGrantRoute
  '/authorization/invalid-redirect-uri': typeof AuthorizationInvalidRedirectUriRoute
  '/authorization/revoke': typeof AuthorizationRevokeRoute
  '/authorization/successful-revoke': typeof AuthorizationSuccessfulRevokeRoute
  '/authorization/update': typeof AuthorizationUpdateRoute
  '/_main/': typeof MainIndexRoute
  '/_main/apps/$id': typeof MainAppsIdRouteWithChildren
  '/_main/settings/pidblocklist': typeof MainSettingsPidblocklistRoute
  '/_main/access-log/': typeof MainAccessLogIndexRoute
  '/_main/apps/': typeof MainAppsIndexRoute
  '/_main/data-validation/': typeof MainDataValidationIndexRoute
  '/_main/settings/': typeof MainSettingsIndexRoute
  '/_main/apps/$id/control-center': typeof MainAppsIdControlCenterRoute
  '/_main/apps/$id/info': typeof MainAppsIdInfoRoute
  '/_main/data-validation/patient': typeof MainDataValidationPatientRouteWithChildren
  '/_main/data-validation/patient/_search': typeof MainDataValidationPatientSearchRouteWithChildren
  '/_main/apps/$id/': typeof MainAppsIdIndexRoute
  '/_main/data-validation/patient/$pid/conditions': typeof MainDataValidationPatientPidConditionsRoute
  '/_main/data-validation/patient/$pid/medication': typeof MainDataValidationPatientPidMedicationRoute
  '/_main/data-validation/patient/$pid/observations': typeof MainDataValidationPatientPidObservationsRoute
  '/_main/data-validation/patient/$pid/patient': typeof MainDataValidationPatientPidPatientRoute
  '/_main/data-validation/patient/$pid/submitted': typeof MainDataValidationPatientPidSubmittedRoute
  '/_main/data-validation/patient/_search/$pid': typeof MainDataValidationPatientSearchPidRoute
  '/_main/data-validation/patient/_search/': typeof MainDataValidationPatientSearchIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/authorization'
    | '/continue'
    | '/login-error'
    | '/unknown-user'
    | '/data-validation'
    | '/authorization/grant'
    | '/authorization/invalid-redirect-uri'
    | '/authorization/revoke'
    | '/authorization/successful-revoke'
    | '/authorization/update'
    | '/'
    | '/apps/$id'
    | '/settings/pidblocklist'
    | '/access-log'
    | '/apps'
    | '/data-validation/'
    | '/settings'
    | '/apps/$id/control-center'
    | '/apps/$id/info'
    | '/data-validation/patient'
    | '/apps/$id/'
    | '/data-validation/patient/$pid/conditions'
    | '/data-validation/patient/$pid/medication'
    | '/data-validation/patient/$pid/observations'
    | '/data-validation/patient/$pid/patient'
    | '/data-validation/patient/$pid/submitted'
    | '/data-validation/patient/$pid'
    | '/data-validation/patient/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/authorization'
    | '/continue'
    | '/login-error'
    | '/unknown-user'
    | '/authorization/grant'
    | '/authorization/invalid-redirect-uri'
    | '/authorization/revoke'
    | '/authorization/successful-revoke'
    | '/authorization/update'
    | '/'
    | '/settings/pidblocklist'
    | '/access-log'
    | '/apps'
    | '/data-validation'
    | '/settings'
    | '/apps/$id/control-center'
    | '/apps/$id/info'
    | '/data-validation/patient'
    | '/apps/$id'
    | '/data-validation/patient/$pid/conditions'
    | '/data-validation/patient/$pid/medication'
    | '/data-validation/patient/$pid/observations'
    | '/data-validation/patient/$pid/patient'
    | '/data-validation/patient/$pid/submitted'
    | '/data-validation/patient/$pid'
  id:
    | '__root__'
    | '/_main'
    | '/authorization'
    | '/continue'
    | '/login-error'
    | '/unknown-user'
    | '/_main/data-validation'
    | '/authorization/grant'
    | '/authorization/invalid-redirect-uri'
    | '/authorization/revoke'
    | '/authorization/successful-revoke'
    | '/authorization/update'
    | '/_main/'
    | '/_main/apps/$id'
    | '/_main/settings/pidblocklist'
    | '/_main/access-log/'
    | '/_main/apps/'
    | '/_main/data-validation/'
    | '/_main/settings/'
    | '/_main/apps/$id/control-center'
    | '/_main/apps/$id/info'
    | '/_main/data-validation/patient'
    | '/_main/data-validation/patient/_search'
    | '/_main/apps/$id/'
    | '/_main/data-validation/patient/$pid/conditions'
    | '/_main/data-validation/patient/$pid/medication'
    | '/_main/data-validation/patient/$pid/observations'
    | '/_main/data-validation/patient/$pid/patient'
    | '/_main/data-validation/patient/$pid/submitted'
    | '/_main/data-validation/patient/_search/$pid'
    | '/_main/data-validation/patient/_search/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  MainRoute: typeof MainRouteWithChildren
  AuthorizationRoute: typeof AuthorizationRouteWithChildren
  ContinueRoute: typeof ContinueRoute
  LoginErrorRoute: typeof LoginErrorRoute
  UnknownUserRoute: typeof UnknownUserRoute
}

const rootRouteChildren: RootRouteChildren = {
  MainRoute: MainRouteWithChildren,
  AuthorizationRoute: AuthorizationRouteWithChildren,
  ContinueRoute: ContinueRoute,
  LoginErrorRoute: LoginErrorRoute,
  UnknownUserRoute: UnknownUserRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_main",
        "/authorization",
        "/continue",
        "/login-error",
        "/unknown-user"
      ]
    },
    "/_main": {
      "filePath": "_main.tsx",
      "children": [
        "/_main/data-validation",
        "/_main/",
        "/_main/apps/$id",
        "/_main/settings/pidblocklist",
        "/_main/access-log/",
        "/_main/apps/",
        "/_main/settings/"
      ]
    },
    "/authorization": {
      "filePath": "authorization.tsx",
      "children": [
        "/authorization/grant",
        "/authorization/invalid-redirect-uri",
        "/authorization/revoke",
        "/authorization/successful-revoke",
        "/authorization/update"
      ]
    },
    "/continue": {
      "filePath": "continue.tsx"
    },
    "/login-error": {
      "filePath": "login-error.tsx"
    },
    "/unknown-user": {
      "filePath": "unknown-user.tsx"
    },
    "/_main/data-validation": {
      "filePath": "_main/data-validation.tsx",
      "parent": "/_main",
      "children": [
        "/_main/data-validation/",
        "/_main/data-validation/patient"
      ]
    },
    "/authorization/grant": {
      "filePath": "authorization/grant.tsx",
      "parent": "/authorization"
    },
    "/authorization/invalid-redirect-uri": {
      "filePath": "authorization/invalid-redirect-uri.tsx",
      "parent": "/authorization"
    },
    "/authorization/revoke": {
      "filePath": "authorization/revoke.tsx",
      "parent": "/authorization"
    },
    "/authorization/successful-revoke": {
      "filePath": "authorization/successful-revoke.tsx",
      "parent": "/authorization"
    },
    "/authorization/update": {
      "filePath": "authorization/update.tsx",
      "parent": "/authorization"
    },
    "/_main/": {
      "filePath": "_main/index.tsx",
      "parent": "/_main"
    },
    "/_main/apps/$id": {
      "filePath": "_main/apps/$id.tsx",
      "parent": "/_main",
      "children": [
        "/_main/apps/$id/control-center",
        "/_main/apps/$id/info",
        "/_main/apps/$id/"
      ]
    },
    "/_main/settings/pidblocklist": {
      "filePath": "_main/settings/pidblocklist.tsx",
      "parent": "/_main"
    },
    "/_main/access-log/": {
      "filePath": "_main/access-log/index.tsx",
      "parent": "/_main"
    },
    "/_main/apps/": {
      "filePath": "_main/apps/index.tsx",
      "parent": "/_main"
    },
    "/_main/data-validation/": {
      "filePath": "_main/data-validation/index.tsx",
      "parent": "/_main/data-validation"
    },
    "/_main/settings/": {
      "filePath": "_main/settings/index.tsx",
      "parent": "/_main"
    },
    "/_main/apps/$id/control-center": {
      "filePath": "_main/apps/$id/control-center.tsx",
      "parent": "/_main/apps/$id"
    },
    "/_main/apps/$id/info": {
      "filePath": "_main/apps/$id/info.tsx",
      "parent": "/_main/apps/$id"
    },
    "/_main/data-validation/patient": {
      "filePath": "_main/data-validation/patient",
      "parent": "/_main/data-validation",
      "children": [
        "/_main/data-validation/patient/_search",
        "/_main/data-validation/patient/$pid/conditions",
        "/_main/data-validation/patient/$pid/medication",
        "/_main/data-validation/patient/$pid/observations",
        "/_main/data-validation/patient/$pid/patient",
        "/_main/data-validation/patient/$pid/submitted"
      ]
    },
    "/_main/data-validation/patient/_search": {
      "filePath": "_main/data-validation/patient/_search.tsx",
      "parent": "/_main/data-validation/patient",
      "children": [
        "/_main/data-validation/patient/_search/$pid",
        "/_main/data-validation/patient/_search/"
      ]
    },
    "/_main/apps/$id/": {
      "filePath": "_main/apps/$id/index.tsx",
      "parent": "/_main/apps/$id"
    },
    "/_main/data-validation/patient/$pid/conditions": {
      "filePath": "_main/data-validation/patient/$pid/conditions.tsx",
      "parent": "/_main/data-validation/patient"
    },
    "/_main/data-validation/patient/$pid/medication": {
      "filePath": "_main/data-validation/patient/$pid/medication.tsx",
      "parent": "/_main/data-validation/patient"
    },
    "/_main/data-validation/patient/$pid/observations": {
      "filePath": "_main/data-validation/patient/$pid/observations.tsx",
      "parent": "/_main/data-validation/patient"
    },
    "/_main/data-validation/patient/$pid/patient": {
      "filePath": "_main/data-validation/patient/$pid/patient.tsx",
      "parent": "/_main/data-validation/patient"
    },
    "/_main/data-validation/patient/$pid/submitted": {
      "filePath": "_main/data-validation/patient/$pid/submitted.tsx",
      "parent": "/_main/data-validation/patient"
    },
    "/_main/data-validation/patient/_search/$pid": {
      "filePath": "_main/data-validation/patient/_search.$pid.tsx",
      "parent": "/_main/data-validation/patient/_search"
    },
    "/_main/data-validation/patient/_search/": {
      "filePath": "_main/data-validation/patient/_search.index.tsx",
      "parent": "/_main/data-validation/patient/_search"
    }
  }
}
ROUTE_MANIFEST_END */

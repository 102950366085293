import { Avatar, Card, Stack, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { GridLayout } from '../../common/components/GridLayout.tsx';
import { RevealMotionContainer } from '../../common/components/RevealMotionContainer.tsx';
import { theme } from '../../theme.ts';

export function ValidationInstructions() {
  return (
    <RevealMotionContainer>
      <Card variant="soft" sx={{ background: theme.vars.palette.background.level1, marginBottom: 2, paddingX: 0 }}>
        <GridLayout columns={3}>
          <Step step={1} />
          <Step step={2} />
          <Step step={3} />
        </GridLayout>
      </Card>
    </RevealMotionContainer>
  );
}

interface StepProps {
  step: number;
}

function Step({ step }: StepProps) {
  const { t } = useTranslation();
  return (
    <Stack alignItems="center" gap={2} paddingY={2} paddingX={4}>
      <Avatar color="primary">{step}</Avatar>
      <Stack gap={1}>
        <Typography level="title-md" textAlign="center">
          {t(`data-validation.instructions.${step}.title`)}
        </Typography>
        <Typography level="body-sm" textAlign="center">
          {t(`data-validation.instructions.${step}.description`)}
        </Typography>
      </Stack>
    </Stack>
  );
}

import { Alert, DialogContent, DialogTitle, Icons, Modal, ModalClose, ModalDialog, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'usehooks-ts';
import { DataValidationResultDto, DataValidationResultStatus } from '../../api/generated.ts';
import { AlertWithAction } from '../../common/components/AlertWithAction.tsx';
import { formatDate } from '../../i18n/date.ts';

interface DataValidationResultProps {
  result: DataValidationResultDto;
}

export function DataValidationResult({ result }: DataValidationResultProps) {
  const { t } = useTranslation();
  const { value: isDialogOpen, setTrue: openDialog, setFalse: closeDialog } = useBoolean(false);
  const date = formatDate(result.created);

  if (result.status === DataValidationResultStatus.CONFIRMED) {
    return (
      <Alert color="success" startDecorator={<Icons.InfoOutlined />}>
        {t('data-validation.footer.confirmed-at', { date })}
      </Alert>
    );
  }

  return (
    <>
      <AlertWithAction
        color="warning"
        startDecorator={<Icons.InfoOutlined />}
        actionLabel={t('show')}
        actionOnClick={openDialog}>
        {t('data-validation.footer.discrepancy-reported-at', { date })}
      </AlertWithAction>

      <Modal open={isDialogOpen} onClose={closeDialog}>
        <ModalDialog maxWidth="sm" minWidth="sm">
          <ModalClose />
          <DialogTitle>{t('data-validation.footer.discrepancy-reported-at', { date })}</DialogTitle>
          <DialogContent>
            <Typography whiteSpace="pre">{result.feedback}</Typography>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  );
}

import { Card, CardProps, Icons } from '@healthinal/ui';
import { LinkOptions } from '@tanstack/react-router';
import { ReactNode } from 'react';
import { RouterLink } from '../../common/components/RouterLink.tsx';
import { hoverShadow } from '../../common/styles/hoverShadow.ts';
import { theme } from '../../theme.ts';
import { ValidationCardContent } from './ValidationCardContent.tsx';

interface ValidationNavigationCardProps extends Omit<CardProps, 'title'> {
  icon: ReactNode;
  title: ReactNode;
  link: LinkOptions;
  disabled?: boolean;
  ghost?: boolean;
}

export function ValidationNavigationCard({
  icon,
  title,
  link,
  disabled,
  children,
  ghost,
  ...cardProps
}: ValidationNavigationCardProps) {
  return (
    <Card
      {...cardProps}
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...(!!ghost || !!disabled
          ? {
              background: 'none',
              ':hover': {
                background: disabled ? undefined : theme.vars.palette.background.level1,
              },
            }
          : hoverShadow),
        ...cardProps.sx,
      }}>
      <ValidationCardContent
        icon={icon}
        title={
          disabled ? (
            title
          ) : (
            <RouterLink {...link} overlay underline="none" textColor="inherit">
              {title}
            </RouterLink>
          )
        }>
        {children}
      </ValidationCardContent>
      {!disabled && <Icons.ChevronRight color="primary" />}
    </Card>
  );
}

import { Icons, Stack } from '@healthinal/ui';
import { createFileRoute } from '@tanstack/react-router';
import { useRef } from 'react';
import FireworksPreset from 'react-canvas-confetti/dist/presets/fireworks';
import { useTranslation } from 'react-i18next';
import { ContentStack } from '../../../../../common/components/ContentStack.tsx';
import { GridLayout } from '../../../../../common/components/GridLayout.tsx';
import { OverviewTitle } from '../../../../../common/components/OverviewTitle.tsx';
import { SubPageHeader } from '../../../../../common/components/SubPageHeader.tsx';
import { PatientHeader } from '../../../../../data-validation/common/PatientHeader.tsx';
import { PatientResources } from '../../../../../data-validation/common/PatientResources.tsx';
import { ValidationNavigationCard } from '../../../../../data-validation/common/ValidationNavigationCard.tsx';
import { ValidationProgressHeader } from '../../../../../data-validation/common/ValidationProgressHeader.tsx';
import { useValidationProgress } from '../../../../../data-validation/useValidationProgress.ts';
import { useValidationResults } from '../../../../../data-validation/useValidationResults.ts';
import { useValidationResourceTypes } from '../../../../../data-validation/validationResourceTypes.ts';
import { heurekaBlue, heurekaTeal, heurekaViolet } from '../../../../../theme.ts';

export interface Search {
  status: 'confirmed' | 'discrepancy-reported';
}

export const Route = createFileRoute('/_main/data-validation/patient/$pid/submitted')({
  component: ValidationResultSubmitted,
  validateSearch: (search: Record<string, unknown>): Search => {
    return {
      status: search.status === 'discrepancy-reported' ? search.status : 'confirmed',
    };
  },
});

function ValidationResultSubmitted() {
  const { t } = useTranslation();
  const { pid } = Route.useParams();
  const { status } = Route.useSearch();

  const { hasFinished, samplesRemaining } = useValidationProgress();
  const validationResourceTypes = useValidationResourceTypes();

  // We use a change in hasFinished to detect if the latest validation result resulted in the validation being finished.
  // In theory, it might be possible for the invalidated results to be re-fetched before this component renders, but
  // in my testing it always worked flawlessly. In the worst case, the user wouldn't see the special title and the fireworks.
  const initialHasFinished = useRef(hasFinished);
  const hasJustFinished = !initialHasFinished.current && hasFinished;

  const results = useValidationResults();
  const patientIsFinished = results.filter((result) => result.pid === pid).length === validationResourceTypes.length;

  return (
    <ContentStack gap={8}>
      <SubPageHeader>
        <PatientHeader pid={pid} compact />
      </SubPageHeader>

      <ValidationProgressHeader
        title={
          hasJustFinished
            ? t('data-validation.finished.title')
            : t('data-validation.submitted.title', { context: status })
        }
        body={
          <>
            {status === 'discrepancy-reported' && (
              <>
                {t('data-validation.submitted.discrepancy-received')}
                <br />
                {!hasFinished && t('data-validation.submitted.discrepancy-continue')}{' '}
              </>
            )}
            {t('data-validation.samples-remaining', { count: samplesRemaining })}{' '}
            {hasFinished && t('data-validation.finished.thank-you')}
          </>
        }
      />

      <Stack gap={6}>
        <Stack gap={2}>
          <OverviewTitle>
            {t('data-validation.submitted.same-patient', { context: patientIsFinished ? 'finished' : undefined })}
          </OverviewTitle>
          <PatientResources pid={pid} replace deEmphasizeValidated />
        </Stack>

        <Stack gap={2}>
          <OverviewTitle>{t('data-validation.submitted.other-options')}</OverviewTitle>
          <GridLayout columns={3}>
            <ValidationNavigationCard
              icon={<Icons.Search />}
              title={t('data-validation.submitted.search-other-patient')}
              link={{ to: '/data-validation/patient' }}
            />
            <ValidationNavigationCard
              icon={<Icons.ExploreOutlined />}
              title={t('data-validation.submitted.to-overview')}
              link={{ to: '/data-validation' }}
            />
          </GridLayout>
        </Stack>
      </Stack>

      {hasJustFinished && <Fireworks />}
    </ContentStack>
  );
}

function Fireworks() {
  return (
    <FireworksPreset
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 10,
      }}
      autorun={{ speed: 3, duration: 1500, delay: 500 }}
      decorateOptions={(options) => ({
        ...options,
        disableForReducedMotion: true,
        colors: [heurekaBlue, heurekaViolet, heurekaTeal],
        ticks: 100, // for how long the particles stay on screen
        scalar: 1.2,
      })}
    />
  );
}

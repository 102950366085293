import { getDisplayName } from '@mui/utils';
import { ComponentType, forwardRef } from 'react';

/** Creates a new component with the given props applied as default */
export function withProps<TProps, TRef = unknown>(Component: ComponentType<TProps>, defaultProps: Partial<TProps>) {
  const WithProps = forwardRef<TRef, TProps>((props, ref) => {
    return <Component {...defaultProps} {...(props as TProps)} ref={ref} />;
  });
  if (import.meta.env.DEV) {
    WithProps.displayName = `WithProps(${getDisplayName(Component)})`;
  }
  return WithProps;
}

import { Button, Checkbox, Stack, Textarea } from '@healthinal/ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreateDataValidationResultDto, DataValidationResultStatus } from '../../api/generated.ts';
import { AlertWithAction } from '../../common/components/AlertWithAction.tsx';
import { ContentStack } from '../../common/components/ContentStack.tsx';
import { ReactHookFormControl } from '../../common/components/ReactHookFormControl.tsx';
import { formatDate } from '../../i18n/date.ts';
import { useSubmitValidationResult } from '../useSubmitValidationResult.ts';
import { useValidationResults } from '../useValidationResults.ts';
import { ValidationResourceType } from '../validationResourceTypes.ts';

interface ReportDiscrepancyFormProps {
  resourceType: ValidationResourceType;
  pid: string;
  onCancel: () => void;
}

type FormValues = Pick<CreateDataValidationResultDto, 'feedback' | 'consentForDebugAccessGiven'>;

export function ReportDiscrepancyForm({ resourceType, pid, onCancel }: ReportDiscrepancyFormProps) {
  const { t } = useTranslation();

  const otherDiscrepancy = useValidationResults(resourceType).find(
    (result) =>
      result.pid !== pid && result.status === DataValidationResultStatus.DISCREPANCY_REPORTED && result.feedback,
  );

  const { register, control, handleSubmit, setValue } = useForm<FormValues>({});

  const { mutateAsync: createValidationResult, isPending } = useSubmitValidationResult(
    pid,
    resourceType,
    DataValidationResultStatus.DISCREPANCY_REPORTED,
  );

  const onSubmit = (values: FormValues) => createValidationResult(values);

  return (
    <ContentStack component="form" onSubmit={handleSubmit(onSubmit)} gap={3}>
      {otherDiscrepancy && (
        <AlertWithAction
          actionLabel={t('data-validation.footer.use-text')}
          actionOnClick={() => setValue('feedback', otherDiscrepancy.feedback ?? '')}>
          {t('data-validation.footer.other-discrepancy', {
            date: formatDate(otherDiscrepancy.created),
          })}
        </AlertWithAction>
      )}

      <ReactHookFormControl name="feedback" control={control}>
        <Textarea
          autoFocus
          minRows={5}
          placeholder={t('data-validation.footer.feedback-placeholder')}
          {...register('feedback', {
            required: t('data-validation.footer.feedback-required'),
          })}
        />
      </ReactHookFormControl>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Checkbox
          {...register('consentForDebugAccessGiven')}
          label={t('data-validation.footer.debug-access-consent')}
        />
        <Stack direction="row" gap={2}>
          <Button variant="soft" color="neutral" onClick={onCancel} disabled={isPending}>
            {t('cancel')}
          </Button>
          <Button color="warning" type="submit" loading={isPending}>
            {t('data-validation.footer.report-discrepancy')}
          </Button>
        </Stack>
      </Stack>
    </ContentStack>
  );
}

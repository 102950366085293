import { Stack, Switch, Typography } from '@healthinal/ui';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntersectionObserver, useLocalStorage } from 'usehooks-ts';
import { ContentStack } from '../../common/components/ContentStack.tsx';
import { ResourceIcon } from '../../common/components/ResourceIcon.tsx';
import { SubPageHeader } from '../../common/components/SubPageHeader.tsx';
import { ValidationResourceType } from '../validationResourceTypes.ts';
import { DataValidationFooter, dataValidationFooterMinHeight } from './DataValidationFooter.tsx';
import { PatientHeader } from './PatientHeader.tsx';
import { ValidationResourceName } from './ValidationResourceName.tsx';

interface ValidationLayoutProps {
  resourceType: ValidationResourceType;
  pid: string;
  children: ReactNode;
  enableFullWidth?: boolean;
}

export function ValidationLayout({ resourceType, pid, children, enableFullWidth }: ValidationLayoutProps) {
  const { t } = useTranslation();
  const [prefersFullWidth, setPrefersFullWidth] = useLocalStorage('data-validation.full-width', false);
  const isFullWidth = enableFullWidth && prefersFullWidth;

  const { isIntersecting: hasSeenAllContent, ref: endOfContentRef } = useIntersectionObserver({
    threshold: 1,
    rootMargin: `-${dataValidationFooterMinHeight}px 0px`, // account for footer
    freezeOnceVisible: true,
  });

  return (
    <>
      <ContentStack paddingBottom={0} gap={8}>
        <SubPageHeader>
          <PatientHeader pid={pid} compact />
        </SubPageHeader>
        <Stack direction="row">
          <Typography level="h4" textColor="text.secondary" startDecorator={<ResourceIcon type={resourceType} />}>
            <ValidationResourceName resourceType={resourceType} />
          </Typography>
          {enableFullWidth && (
            <Switch
              size="sm"
              checked={prefersFullWidth}
              onChange={(e) => setPrefersFullWidth(e.target.checked)}
              sx={{ gap: 1, marginLeft: 'auto' }}
              endDecorator={
                <Typography level="title-sm" textColor="text.secondary">
                  {t('full-width')}
                </Typography>
              }
            />
          )}
        </Stack>
      </ContentStack>

      <ContentStack fullWidth={isFullWidth} paddingTop={3}>
        {children}
        <span ref={endOfContentRef} />
      </ContentStack>

      <DataValidationFooter resourceType={resourceType} pid={pid} hasSeenAllContent={hasSeenAllContent} />
    </>
  );
}

import { createFileRoute } from '@tanstack/react-router';
import { ValidationLayout } from '../../../../../data-validation/common/ValidationLayout.tsx';
import { ObservationsView } from '../../../../../data-validation/observations/ObservationsView.tsx';

export const Route = createFileRoute('/_main/data-validation/patient/$pid/observations')({
  component: Observations,
});

function Observations() {
  const { pid } = Route.useParams();

  return (
    <ValidationLayout resourceType="OBSERVATION" pid={pid}>
      <ObservationsView pid={pid} />
    </ValidationLayout>
  );
}

import { Card, IconButton, Icons, Stack, StackProps, Tooltip, Typography } from '@healthinal/ui';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'usehooks-ts';
import { formatDate } from '../../i18n/date.ts';
import { theme } from '../../theme.ts';
import { getPid } from '../patient/utils.ts';
import { usePatientData } from '../usePatientData.tsx';

interface PatientHeaderProps {
  pid: string;
  compact?: boolean;
}

export function PatientHeader({ pid, compact }: PatientHeaderProps) {
  const { t } = useTranslation();
  const { data } = usePatientData(pid);
  const [censored, toggleCensored] = useToggle(true);

  const names = [...data.patient.name[0].given, data.patient.name[0].family].filter((name) => name !== undefined);

  return (
    <Card
      variant="soft"
      sx={{
        background: theme.vars.palette.background.level1,
        gap: 2,
        ...(compact && {
          flexDirection: 'row',
          paddingY: 1,
          alignItems: 'center',
        }),
      }}>
      <Stack direction="row" alignItems="center" gap={1}>
        <CensoredName names={names} censored={censored} typography={compact ? 'title-sm' : 'h4'} />
        <Tooltip title={t('data-validation.censored-name-info')} sx={{ maxWidth: 400 }}>
          <IconButton size="sm" onClick={toggleCensored}>
            {censored ? <Icons.VisibilityOutlined fontSize="md" /> : <Icons.VisibilityOffOutlined fontSize="md" />}
          </IconButton>
        </Tooltip>
      </Stack>

      <Stack direction="row" gap={3}>
        <Stack direction="row" gap={1}>
          <Typography level="body-sm">{t('data-validation.patient.birthdate')}</Typography>
          <Typography level="body-sm" textColor="text.primary">
            {formatDate(data.patient.birthDate)}
          </Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <Typography level="body-sm">{t('data-validation.patient.pid')}</Typography>
          <Typography level="body-sm" textColor="text.primary">
            {getPid(data.patient)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

interface CensoredNameProps extends StackProps {
  names: string[];
  censored: boolean;
}

function CensoredName({ names, censored, ...stackProps }: CensoredNameProps) {
  return (
    <Stack {...stackProps}>
      <span style={censored ? undefined : hiddenStyle}>
        {names.map((name) => name[0] + '⚹'.repeat(name.length - 1)).join(' ')}
      </span>
      <span style={censored ? hiddenStyle : undefined}>{names.join(' ')}</span>
    </Stack>
  );
}

const hiddenStyle: CSSProperties = {
  visibility: 'hidden',
  height: 0,
};

import { keyframes } from '@mui/system';
import { contentWidth } from '../../common/components/ContentStack.tsx';
import { heurekaViolet } from '../../theme.ts';

export const flowAnimationInterval = 4000;
const flowAnimationDuration = 2000;
const flowAnimationWidth = 240;

// due to background-attachment: fixed, the background position is relative to the entire viewport
const backgroundPositionOffset = `calc((100vw - ${contentWidth}px) / 2)`;
const backgroundPositionStart = `calc(${backgroundPositionOffset} - ${flowAnimationWidth}px)`;
const backgroundPositionEnd = `calc(${backgroundPositionOffset} + ${contentWidth}px)`;

const animation = keyframes({
  '0%': {
    backgroundPositionX: backgroundPositionStart,
  },
  [`${(flowAnimationDuration / flowAnimationInterval) * 100}%`]: {
    backgroundPositionX: backgroundPositionEnd,
  },
  '100%': {
    backgroundPositionX: backgroundPositionEnd,
  },
});

const background = `color-mix(in srgb, ${heurekaViolet} 32%, white)`;

export const flowAnimationStyles = {
  backgroundAttachment: 'fixed', // allows us to animate the same background across multiple elements as if it was one
  backgroundColor: background,
  backgroundImage: `linear-gradient(to right, ${background} 0, ${heurekaViolet} ${flowAnimationWidth}px, ${background} ${flowAnimationWidth}px)`,
  backgroundRepeat: 'no-repeat',
  animation: `${animation} ${flowAnimationInterval}ms infinite linear`,
};

import { Stack, StackProps } from '@healthinal/ui';

interface ContentStackProps extends StackProps {
  fullWidth?: boolean;
}

export const contentWidth = 1024;
const contentPaddingX = 2;

export function ContentStack({ fullWidth, ...stackProps }: ContentStackProps) {
  return (
    <Stack
      width="100%"
      maxWidth={fullWidth ? '100%' : contentWidth + contentPaddingX * 2 * 8}
      marginX="auto"
      paddingY={5}
      paddingX={contentPaddingX}
      sx={{
        transition: fullWidth !== undefined ? 'max-width 400ms' : undefined,
      }}
      {...stackProps}
    />
  );
}

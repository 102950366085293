import { Stack, styled, Typography } from '@healthinal/ui';
import { ReactNode } from 'react';

interface ValidationCardContentProps {
  icon: ReactNode;
  title: ReactNode;
  children?: ReactNode;
}

export function ValidationCardContent({ icon, title, children }: ValidationCardContentProps) {
  return (
    <ValidationCardContentLayout>
      <Stack alignItems="center">{icon}</Stack>
      <Typography component="div" level="title-md" textColor="text.secondary">
        {title}
      </Typography>
      {children}
    </ValidationCardContentLayout>
  );
}

export const ValidationCardContentLayout = styled('div')({
  display: 'grid',
  gridTemplateColumns: '28px 1fr', // 28px is the space needed by the sample status dots
  columnGap: 12,
  rowGap: 8,
  alignItems: 'center',
});

import { Alert, AlertProps, Button, ButtonProps } from '@healthinal/ui';
import { ReactNode } from 'react';

interface AlertWithActionProps extends Omit<AlertProps, 'endDecorator'> {
  actionLabel: ReactNode;
  actionOnClick: ButtonProps['onClick'];
}

export function AlertWithAction({
  actionLabel,
  actionOnClick,
  color = 'neutral',
  ...alertProps
}: AlertWithActionProps) {
  return (
    <Alert
      {...alertProps}
      sx={{
        padding: 0.5,
        paddingLeft: 2,
        ...alertProps.sx,
      }}
      color={color}
      endDecorator={
        <Button variant="plain" size="sm" color={color === 'neutral' ? 'primary' : color} onClick={actionOnClick}>
          {actionLabel}
        </Button>
      }
    />
  );
}

import { Box, Stack, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { DataValidationResultStatus } from '../../api/generated.ts';
import { theme } from '../../theme.ts';
import { samplesPerResourceType } from '../useValidationProgress.ts';
import { useValidationResults } from '../useValidationResults.ts';
import { ValidationResourceType } from '../validationResourceTypes.ts';

interface ValidationSampleStatusProps {
  resourceType: ValidationResourceType;
  subtle?: boolean;
}

export function ValidationSampleStatus({ resourceType, subtle }: ValidationSampleStatusProps) {
  const results = useValidationResults(resourceType);
  const { t } = useTranslation();
  return (
    <>
      <Stack direction="row" gap={0.5}>
        {Array.from({ length: samplesPerResourceType }).map((_, index) => (
          <StatusDot key={index} status={results[index]?.status} subtle={subtle} />
        ))}
      </Stack>
      <Typography level="body-sm">
        {t('data-validation.samples-remaining-resource', {
          count: Math.max(0, samplesPerResourceType - results.length),
        })}
      </Typography>
    </>
  );
}

interface StatusDotProps {
  status: DataValidationResultStatus | undefined;
  subtle?: boolean;
}

function StatusDot({ status, subtle }: StatusDotProps) {
  let background =
    status &&
    (status === DataValidationResultStatus.CONFIRMED
      ? theme.vars.palette.success.plainColor
      : theme.vars.palette.warning.plainColor);

  if (background && subtle) {
    background = theme.vars.palette.neutral.outlinedBorder;
  }

  return (
    <Box
      sx={{
        width: 12,
        height: 12,
        borderRadius: '50%',
        border: background === undefined ? `solid 1px ${theme.vars.palette.neutral.outlinedBorder}` : undefined,
        background,
      }}
    />
  );
}

import { createFileRoute, Outlet } from '@tanstack/react-router';
import { Suspense } from 'react';
import { Header } from '../common/components/Header.tsx';
import { SplashScreen } from '../common/components/SplashScreen.tsx';
import { ErrorBoundary } from '../errors/ErrorBoundary.tsx';

export const Route = createFileRoute('/_main')({
  component: MainLayout,
});

function MainLayout() {
  return (
    <>
      <Header />
      <Suspense fallback={<SplashScreen />}>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Suspense>
    </>
  );
}

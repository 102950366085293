import { Button, Card, Chip, Icons, Stack, Typography } from '@healthinal/ui';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { useReadDataProjectSuspense } from '../../../api/generated.ts';
import { ContentStack } from '../../../common/components/ContentStack.tsx';
import { SubPageHeader } from '../../../common/components/SubPageHeader.tsx';
import { TabLink } from '../../../common/components/TabLink.tsx';
import { RevokeGrantSuccessProvider } from '../../../data-projects/RevokeGrantSuccessProvider.tsx';
import { useIsDataProjectGranted } from '../../../data-projects/useIsDataProjectGranted.ts';

export const Route = createFileRoute('/_main/apps/$id')({
  component: DataProjectDetails,
});

function DataProjectDetails() {
  const { t } = useTranslation();
  const { id } = Route.useParams();
  const { data: dataProject } = useReadDataProjectSuspense(id);
  const isDataProjectGranted = useIsDataProjectGranted(id);

  return (
    <ContentStack>
      <Stack gap={5}>
        <SubPageHeader />

        <Stack direction="row" gap={3}>
          <img src={dataProject.logo} alt="" width={112} height={112} />
          <Stack gap={2}>
            <Stack>
              <Typography level="h2">{dataProject.name}</Typography>
              <Typography level="body-lg" textColor="text.tertiary">
                {dataProject.provider}
              </Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              {dataProject.categories.map((category) => (
                <Chip key={category.key}>{category.name}</Chip>
              ))}
            </Stack>
          </Stack>
        </Stack>

        {isDataProjectGranted && (
          <Card orientation="horizontal" sx={{ background: 'none', justifyContent: 'space-between' }}>
            <Stack direction="row" gap="24px">
              <TabLink from={Route.fullPath} to="./control-center">
                {t('control-center')}
              </TabLink>
              <TabLink from={Route.fullPath} to="./info">
                {t('information')}
              </TabLink>
            </Stack>
            {dataProject.launchUrl && (
              <Button component="a" href={dataProject.launchUrl} target={'_blank'} endDecorator={<Icons.Launch />}>
                {t('launch-application')}
              </Button>
            )}
          </Card>
        )}

        <RevokeGrantSuccessProvider dataProject={dataProject}>
          <Outlet />
        </RevokeGrantSuccessProvider>
      </Stack>
    </ContentStack>
  );
}

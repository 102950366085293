import { DataValidationResultDto, useGetValidationResultsSuspense } from '../api/generated.ts';
import { useHealthcareProviderId } from '../user/useHealthcareProviderId.ts';
import { ValidationResourceType } from './validationResourceTypes.ts';

export function useValidationResults(resourceType?: ValidationResourceType): DataValidationResultDto[] {
  const healthcareProviderId = useHealthcareProviderId();
  const { data: results } = useGetValidationResultsSuspense(healthcareProviderId);
  if (resourceType) {
    return results.filter((result) => result.resourceType === resourceType);
  }
  return results;
}

import { Button, Icons, Stack, styled, Typography } from '@healthinal/ui';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'usehooks-ts';
import { DataValidationResultStatus } from '../../api/generated.ts';
import { ContentStack } from '../../common/components/ContentStack.tsx';
import { RevealMotionContainer } from '../../common/components/RevealMotionContainer.tsx';
import { theme } from '../../theme.ts';
import { useSubmitValidationResult } from '../useSubmitValidationResult.ts';
import { useValidationResults } from '../useValidationResults.ts';
import { ValidationResourceType } from '../validationResourceTypes.ts';
import { DataValidationResult } from './DataValidationResult.tsx';
import { ReportDiscrepancyForm } from './ReportDiscrepancyForm.tsx';

interface DataValidationFooterProps {
  resourceType: ValidationResourceType;
  pid: string;
  hasSeenAllContent: boolean;
}

export function DataValidationFooter({ resourceType, pid, hasSeenAllContent }: DataValidationFooterProps) {
  const { t } = useTranslation();

  const validationResult = useValidationResults(resourceType).find((result) => result.pid === pid);

  const { value: isReportingDiscrepancy, setTrue: reportDiscrepancy, setFalse: cancelDiscrepancy } = useBoolean(false);

  const { mutate: createValidationResult, isPending } = useSubmitValidationResult(
    pid,
    resourceType,
    DataValidationResultStatus.CONFIRMED,
  );

  const onConfirm = () => createValidationResult({ consentForDebugAccessGiven: false });

  return (
    <Footer>
      <AnimatePresence initial={false}>
        {isReportingDiscrepancy ? (
          <RevealMotionContainer key="report-discrepancy">
            <ReportDiscrepancyForm resourceType={resourceType} pid={pid} onCancel={cancelDiscrepancy} />
          </RevealMotionContainer>
        ) : (
          <RevealMotionContainer
            key="footer"
            transition={{ opacity: { type: false /* it looks cleaner if the opacity changes instantly */ } }}>
            <ContentStack paddingY={2} direction="row" alignItems="center" justifyContent="space-between">
              {validationResult ? (
                <DataValidationResult result={validationResult} />
              ) : (
                <Typography>{t('data-validation.footer.instruction')}</Typography>
              )}
              {hasSeenAllContent ? (
                <Stack direction="row" gap={2}>
                  <Button
                    variant="soft"
                    color="warning"
                    startDecorator={<Icons.Close />}
                    disabled={isPending}
                    onClick={reportDiscrepancy}>
                    {t('data-validation.footer.has-discrepancy')}
                  </Button>
                  <Button color="success" startDecorator={<Icons.Check />} loading={isPending} onClick={onConfirm}>
                    {t('data-validation.footer.confirm')}
                  </Button>
                </Stack>
              ) : (
                <Typography textColor="text.tertiary" endDecorator={<Icons.KeyboardDoubleArrowDown />}>
                  {t('data-validation.footer.scroll-down')}
                </Typography>
              )}
            </ContentStack>
          </RevealMotionContainer>
        )}
      </AnimatePresence>
    </Footer>
  );
}

export const dataValidationFooterMinHeight = 80;

const Footer = styled(Stack)({
  minHeight: dataValidationFooterMinHeight,
  position: 'sticky',
  bottom: 0,
  marginTop: 'auto',
  zIndex: 2,
  justifyContent: 'center',
  backdropFilter: 'blur(16px)',
  background: `color-mix(in srgb, ${theme.vars.palette.background.surface} 64%, transparent)`,
  borderTop: `solid ${theme.vars.palette.divider} 1px`,
});

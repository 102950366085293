import { IconButton, Icons, Input } from '@healthinal/ui';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentStack } from '../../../../common/components/ContentStack.tsx';
import { SplashScreen } from '../../../../common/components/SplashScreen.tsx';
import { SubPageHeader } from '../../../../common/components/SubPageHeader.tsx';
import { ErrorBoundary } from '../../../../errors/ErrorBoundary.tsx';

export const Route = createFileRoute('/_main/data-validation/patient/_search')({
  component: Search,
});

function Search() {
  const navigate = Route.useNavigate();
  const [searchInput, setSearchInput] = useState('');
  const { t } = useTranslation();

  return (
    <ContentStack gap={8}>
      <SubPageHeader>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            void navigate({
              to: '/data-validation/patient/$pid',
              params: { pid: searchInput },
              replace: true,
            });
            setSearchInput('');
          }}>
          <Input
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
            size="lg"
            sx={{ width: 400 }}
            placeholder={t('data-validation.patient.pid')}
            autoFocus
            endDecorator={
              <IconButton type="submit" tabIndex={-1}>
                <Icons.Search />
              </IconButton>
            }
          />
        </form>
      </SubPageHeader>
      <Suspense fallback={<SplashScreen />}>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Suspense>
    </ContentStack>
  );
}

import { Stack } from '@healthinal/ui';
import { HeurekaLogo } from './HeurekaLogo.tsx';

export function AuthHeader() {
  return (
    <Stack height={80} justifyContent="center" alignItems="center" marginBottom={5}>
      <a href="https://heureka.health" target="_blank" rel="noreferrer">
        <HeurekaLogo height={32} />
      </a>
    </Stack>
  );
}

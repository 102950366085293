import { useFeatureFlagEnabled } from 'posthog-js/react';
import { DataValidationDto, ResourceType } from '../api/generated.ts';
import { FileRoutesByFullPath } from '../routes/-generated.ts';

export type ValidationResourceTypeRoute = Extract<
  keyof FileRoutesByFullPath,
  `/data-validation/patient/$pid/${string}`
>;

export interface ValidationResourceTypeConfig {
  resourceType: ResourceType;
  route: ValidationResourceTypeRoute;
  getCount: (data: DataValidationDto) => number | undefined;
}

export function useValidationResourceTypes() {
  const observationsValidationEnabled = useFeatureFlagEnabled('data-validation-observations');
  return [
    {
      resourceType: ResourceType.PATIENT,
      route: '/data-validation/patient/$pid/patient',
      getCount: () => undefined,
    },
    {
      resourceType: ResourceType.MEDICATION_STATEMENT,
      route: '/data-validation/patient/$pid/medication',
      getCount: (data) => data.medicationStatements.length,
    },
    {
      resourceType: ResourceType.CONDITION,
      route: '/data-validation/patient/$pid/conditions',
      getCount: (data) => data.conditions.length,
    },
    ...(observationsValidationEnabled
      ? [
          {
            resourceType: ResourceType.OBSERVATION,
            route: '/data-validation/patient/$pid/observations',
            getCount: (data) => data.observations.length,
          } satisfies ValidationResourceTypeConfig,
        ]
      : []),
  ] satisfies ValidationResourceTypeConfig[];
}

export type ValidationResourceType = ReturnType<typeof useValidationResourceTypes>[0]['resourceType'];

export function useValidationResourceRoutes() {
  return Object.fromEntries(
    useValidationResourceTypes().map(({ resourceType, route }) => [resourceType, route]),
  ) as Record<ValidationResourceType, ValidationResourceTypeConfig['route']>;
}

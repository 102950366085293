import { Card, Stack } from '@healthinal/ui';
import { createFileRoute } from '@tanstack/react-router';
import { Suspense } from 'react';
import { AccessLogTable } from '../../../access-log/AccessLogTable.tsx';
import { ContentStack } from '../../../common/components/ContentStack.tsx';
import { headerHeight } from '../../../common/components/Header.tsx';
import { HeurekaSpinner } from '../../../common/components/HeurekaSpinner.tsx';

export const Route = createFileRoute('/_main/access-log/')({
  component: AccessLog,
});

function AccessLog() {
  return (
    <ContentStack height={`calc(100vh - ${headerHeight}px)`}>
      <Card sx={{ maxHeight: '100%', overflowY: 'auto', padding: 0 }}>
        <Suspense
          fallback={
            <Stack alignItems="center" justifyContent="center" height={400}>
              <HeurekaSpinner />
            </Stack>
          }>
          <AccessLogTable />
        </Suspense>
      </Card>
    </ContentStack>
  );
}

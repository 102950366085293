import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import {
  CreateDataValidationResultDto,
  DataValidationResultStatus,
  getGetValidationResultsQueryKey,
  ResourceType,
  useCreateValidationResult,
} from '../api/generated.ts';
import { useHealthcareProviderId } from '../user/useHealthcareProviderId.ts';

type MutateOptions = Pick<CreateDataValidationResultDto, 'feedback' | 'consentForDebugAccessGiven'>;

export function useSubmitValidationResult(pid: string, resourceType: ResourceType, status: DataValidationResultStatus) {
  const healthcareProviderId = useHealthcareProviderId();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useCreateValidationResult({
    mutation: {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: getGetValidationResultsQueryKey(healthcareProviderId),
        });
        void navigate({
          to: '/data-validation/patient/$pid/submitted',
          params: { pid },
          search: {
            status: status === DataValidationResultStatus.DISCREPANCY_REPORTED ? 'discrepancy-reported' : 'confirmed',
          },
          replace: true,
        });
      },
    },
  });

  return {
    ...mutation,
    mutateAsync: (options: MutateOptions) =>
      mutation.mutateAsync({ healthcareProviderId, data: { pid, resourceType, status, ...options } }),
    mutate: (options: MutateOptions) =>
      mutation.mutate({ healthcareProviderId, data: { pid, resourceType, status, ...options } }),
  };
}

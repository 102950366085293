import { useTranslation } from 'react-i18next';
import { ResourceType } from '../../api/generated.ts';

interface ValidationResourceNameProps {
  resourceType: ResourceType;
}

export function ValidationResourceName({ resourceType }: ValidationResourceNameProps) {
  const { t } = useTranslation();
  return t(`resource-type.name.${resourceType}`, { context: 'data-validation' });
}

import { Divider, DividerProps, IconButton, Icons, MenuButton, Stack, styled } from '@healthinal/ui';
import { Link } from '@tanstack/react-router';
import { motion, useScroll, useTransform } from 'framer-motion';
import { ForwardRefExoticComponent } from 'react';
import { theme } from '../../theme.ts';
import { ContentStack } from './ContentStack.tsx';
import { HelpMenu } from './HelpMenu.tsx';
import { HeurekaLogo } from './HeurekaLogo.tsx';
import { Navigation } from './Navigation.tsx';
import { UserMenu } from './UserMenu.tsx';

export const headerHeight = 80;
const MotionDivider = motion.create<Omit<DividerProps, 'style'>>(Divider as ForwardRefExoticComponent<DividerProps>);

export function Header() {
  const { scrollY } = useScroll();
  const dividerOpacity = useTransform(scrollY, [0, 160], [0, 1], { clamp: true }); // slowly fade divider in as user scrolls down

  return (
    <HeaderContainer>
      <ContentStack justifyContent="space-between" alignItems="center" direction="row" flex={1} paddingY={0}>
        <Link to="/" style={{ display: 'flex' }}>
          <HeurekaLogo width={100} />
        </Link>
        <Navigation />
        <Stack direction="row" alignItems="center" gap={2}>
          <HelpMenu>
            <MenuButton slots={{ root: IconButton }}>
              <Icons.HelpOutline />
            </MenuButton>
          </HelpMenu>
          <Link to="/settings">
            {({ isActive }) => (
              <IconButton
                component="span"
                variant={isActive ? 'soft' : 'plain'}
                color={isActive ? 'primary' : 'neutral'}>
                <Icons.SettingsOutlined />
              </IconButton>
            )}
          </Link>
          <UserMenu />
        </Stack>
      </ContentStack>
      <MotionDivider style={{ opacity: dividerOpacity }} />
    </HeaderContainer>
  );
}

const HeaderContainer = styled(Stack)({
  position: 'sticky',
  zIndex: 2,
  top: 0,
  width: '100%',
  alignItems: 'center',
  height: headerHeight,
  backdropFilter: 'blur(16px)',
  background: `color-mix(in srgb, ${theme.vars.palette.background.body} 64%, transparent)`,
});

import { Stack, styled, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { DataValidationResultStatus } from '../../api/generated.ts';
import { GridLayout } from '../../common/components/GridLayout.tsx';
import { ResourceIcon } from '../../common/components/ResourceIcon.tsx';
import { theme } from '../../theme.ts';
import { usePatientData } from '../usePatientData.tsx';
import { useValidationResults } from '../useValidationResults.ts';
import {
  useValidationResourceTypes,
  ValidationResourceType,
  ValidationResourceTypeRoute,
} from '../validationResourceTypes.ts';
import { ValidationCardContentLayout } from './ValidationCardContent.tsx';
import { ValidationNavigationCard } from './ValidationNavigationCard.tsx';
import { ValidationResourceName } from './ValidationResourceName.tsx';
import { ValidationSampleStatus } from './ValidationSampleStatus.tsx';

interface PatientResourcesProps {
  pid: string;
  replace?: boolean;
  deEmphasizeValidated?: boolean;
}

export function PatientResources({ pid, replace, deEmphasizeValidated }: PatientResourcesProps) {
  const { data } = usePatientData(pid);
  const validationResourceTypes = useValidationResourceTypes();

  return (
    <GridLayout columns={3} as="ul">
      {validationResourceTypes.map(({ resourceType, route, getCount }) => (
        <PatientResourceCard
          key={resourceType}
          resourceType={resourceType}
          route={route}
          pid={pid}
          replace={replace}
          deEmphasizeValidated={deEmphasizeValidated}
          count={getCount(data)}
        />
      ))}
    </GridLayout>
  );
}

interface PatientResourceCardProps extends PatientResourcesProps {
  resourceType: ValidationResourceType;
  route: ValidationResourceTypeRoute;
  count?: number;
}

function PatientResourceCard({
  resourceType,
  route,
  count,
  pid,
  replace,
  deEmphasizeValidated,
}: PatientResourceCardProps) {
  const validationResult = useValidationResults(resourceType).find((result) => result.pid === pid);
  return (
    <Stack component="li" gap={1}>
      <ValidationCardContentLayout sx={{ marginLeft: 2 }}>
        <ValidationSampleStatus resourceType={resourceType} subtle />
      </ValidationCardContentLayout>

      <ValidationNavigationCard
        disabled={count === 0}
        ghost={deEmphasizeValidated && !!validationResult}
        icon={<ResourceIcon type={resourceType} />}
        title={
          <Stack direction="row" alignItems="center" gap={1}>
            <ValidationResourceName resourceType={resourceType} />
            {count !== 0 && count !== undefined && <Count>{count}</Count>}
          </Stack>
        }
        link={{ to: route, params: { pid }, replace }}>
        <span /* placeholder for the first grid column */ />
        <Typography level="body-sm">
          <SampleStatusText status={validationResult?.status} count={count} />
        </Typography>
      </ValidationNavigationCard>
    </Stack>
  );
}

interface SampleStatusTextProps {
  status: DataValidationResultStatus | undefined;
  count: number | undefined;
}

function SampleStatusText({ status, count }: SampleStatusTextProps) {
  const { t } = useTranslation();
  if (count === 0) {
    return t('data-validation.sample-status.no-data');
  }
  switch (status) {
    case DataValidationResultStatus.CONFIRMED:
      return <Typography color="success">{t('data-validation.sample-status.confirmed')}</Typography>;
    case DataValidationResultStatus.DISCREPANCY_REPORTED:
      return <Typography color="warning">{t('data-validation.sample-status.discrepancy-reported')}</Typography>;
    default:
      return t('data-validation.sample-status.pending');
  }
}

const Count = styled(Stack)({
  ...theme.typography['body-xs'],
  color: theme.vars.palette.text.primary,
  background: theme.vars.palette.neutral.softBg,
  height: 20,
  minWidth: 20,
  paddingInline: 6,
  borderRadius: theme.vars.radius.lg,
  justifyContent: 'center',
  alignItems: 'center',
});

import { CircularProgress, Stack, Typography } from '@healthinal/ui';
import { ReactNode } from 'react';
import { useValidationProgress } from '../useValidationProgress.ts';

interface ValidationProgressHeaderProps {
  title: ReactNode;
  body: ReactNode;
}

export function ValidationProgressHeader({ title, body }: ValidationProgressHeaderProps) {
  const { samplesChecked, samplesTotal } = useValidationProgress();

  return (
    <Stack direction="row" alignItems="center" gap={5}>
      <ProgressCircle value={samplesChecked} total={samplesTotal} />
      <Stack gap={1}>
        <Typography level="h4">{title}</Typography>
        <Typography>{body}</Typography>
      </Stack>
    </Stack>
  );
}

interface ProgressCircleProps {
  value: number;
  total: number;
}

function ProgressCircle({ value, total }: ProgressCircleProps) {
  return (
    <CircularProgress
      determinate
      value={(value / total) * 100}
      sx={{
        '--CircularProgress-size': '96px',
        '--CircularProgress-thickness': '8px',
      }}
      slotProps={{
        progress: {
          sx: {
            transition: 'stroke-dashoffset 800ms',
          },
        },
      }}>
      <Typography color="primary" level="title-lg">
        {value} / {total}
      </Typography>
    </CircularProgress>
  );
}

import { Button, Card, Icons, Stack, styled, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { DataValidationResultDto } from '../../api/generated.ts';
import { ResourceIcon } from '../../common/components/ResourceIcon.tsx';
import { RouterLink } from '../../common/components/RouterLink.tsx';
import { StyledTable } from '../../common/components/StyledTable.tsx';
import { formatDate } from '../../i18n/date.ts';
import { theme } from '../../theme.ts';
import { ValidationResourceName } from '../common/ValidationResourceName.tsx';
import { useValidationResourceRoutes, ValidationResourceType } from '../validationResourceTypes.ts';

interface ReportedDiscrepanciesProps {
  results: DataValidationResultDto[];
}

export function ReportedDiscrepancies({ results }: ReportedDiscrepanciesProps) {
  const { t } = useTranslation();
  const validationResourceRoutes = useValidationResourceRoutes();
  return (
    <Card sx={{ padding: 0, overflow: 'hidden' }}>
      <StyledTable>
        <thead>
          <tr>
            <th style={{ width: 120 }}>{t('data-validation.discrepancies.reported-at')}</th>
            <th style={{ width: 160 }}>{t('data-validation.resource-type')}</th>
            <th style={{ width: 120 }}>{t('data-validation.patient.pid')}</th>
            <th>{t('data-validation.discrepancies.feedback')}</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result) => (
            <InteractiveTableRow key={result.pid + result.resourceType}>
              <td>
                <RouterLink
                  overlay
                  underline="none"
                  textColor="inherit"
                  to={validationResourceRoutes[result.resourceType as ValidationResourceType]}
                  params={{ pid: result.pid }}>
                  {formatDate(result.created)}
                </RouterLink>
              </td>
              <td>
                <Stack direction="row" gap={1}>
                  <ResourceIcon type={result.resourceType} size="sm" />
                  <ValidationResourceName resourceType={result.resourceType} />
                </Stack>
              </td>
              <td>{result.pid}</td>
              <td>
                <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} marginRight={-1}>
                  <Typography noWrap>{result.feedback}</Typography>
                  <Button
                    // this button is purely decorative because the entire row is interactive
                    sx={{ pointerEvents: 'none', flexShrink: 0 }}
                    variant="plain"
                    size="sm"
                    endDecorator={<Icons.ChevronRight />}>
                    {t('data-validation.discrepancies.validate-again')}
                  </Button>
                </Stack>
              </td>
            </InteractiveTableRow>
          ))}
        </tbody>
      </StyledTable>
    </Card>
  );
}

const InteractiveTableRow = styled('tr')({
  position: 'relative', // allows the link with `overlay` prop to make entire row clickable
  ':hover': {
    background: theme.vars.palette.background.body,
  },
});

import { Card, CardOverflow, CardProps, Divider, Icons, Stack, styled, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { PortalDataProject } from '../api/generated.ts';
import { RouterLink } from '../common/components/RouterLink.tsx';
import { hoverShadow } from '../common/styles/hoverShadow.ts';
import { useIsDataProjectGranted } from './useIsDataProjectGranted.ts';

export interface DataProjectCardProps extends CardProps {
  dataProject: PortalDataProject;
  showDescription?: boolean;
  showGrantState?: boolean;
}

export function DataProjectCard({ dataProject, showDescription, showGrantState, ...cardProps }: DataProjectCardProps) {
  const { t } = useTranslation();
  const isDataProjectGranted = useIsDataProjectGranted(dataProject.id);
  const isDataProjectDetailsAllowed = !dataProject.inPreview || isDataProjectGranted;

  return (
    <StyledCard sx={isDataProjectDetailsAllowed ? hoverShadow : {}} {...cardProps}>
      <Stack direction="row" gap={2}>
        <img src={dataProject.logo} alt="" width={64} height={64} />
        <Stack justifyContent="center">
          <Typography level="h3">
            {isDataProjectDetailsAllowed ? (
              <RouterLink to="/apps/$id" params={{ id: dataProject.id }} overlay underline="none" textColor="inherit">
                {dataProject.name}
              </RouterLink>
            ) : (
              dataProject.name
            )}
          </Typography>
          <Typography level="body-sm">{dataProject.provider}</Typography>
        </Stack>
      </Stack>
      {showDescription && (
        <Typography height="100%" level="body-sm">
          {dataProject.descriptionShort}
        </Typography>
      )}
      <CardOverflow sx={{ position: 'static' }}>
        <Divider inset="context" />
        <Stack paddingY={2} direction="row">
          {showGrantState && isDataProjectGranted && (
            <Typography color="success" level="body-sm" startDecorator={<Icons.Check />}>
              {t('connected')}
            </Typography>
          )}
          {dataProject.inPreview && showGrantState && !isDataProjectGranted && (
            <Typography level="body-sm" startDecorator={<Icons.AccessTimeOutlined />}>
              {t('coming-soon')}
            </Typography>
          )}
          {isDataProjectDetailsAllowed && (
            <Typography
              level="title-sm"
              color="primary"
              endDecorator={<Icons.ChevronRight />}
              aria-hidden
              marginLeft="auto">
              {isDataProjectGranted ? t('manage') : t('learn-more')}
            </Typography>
          )}
        </Stack>
      </CardOverflow>
    </StyledCard>
  );
}

const StyledCard = styled(Card)({
  '--Card-padding': '24px',
  gap: 'var(--Card-padding)',
});

import { Icons, SvgIconProps } from '@healthinal/ui';
import { ComponentType } from 'react';
import { ResourceType } from '../../api/generated.ts';

const icons: Record<ResourceType, ComponentType> = {
  PATIENT: Icons.Person,
  MEDICATION_STATEMENT: Icons.Vaccines,
  OBSERVATION: Icons.Assessment,
  CONDITION: Icons.Flag,
  APPOINTMENT: Icons.Today,
  DOCUMENT_REFERENCE: Icons.Description,
};

interface ResourceIconProps extends SvgIconProps {
  type: ResourceType;
}

export function ResourceIcon({ type, ...iconProps }: ResourceIconProps) {
  const Icon = icons[type] ?? Icons.QuestionMark;
  return <Icon {...iconProps} />;
}

import { createFileRoute } from '@tanstack/react-router';
import { ValidationLayout } from '../../../../../data-validation/common/ValidationLayout.tsx';
import { ConditionsView } from '../../../../../data-validation/conditions/ConditionsView.tsx';

export const Route = createFileRoute('/_main/data-validation/patient/$pid/conditions')({
  component: Conditions,
});

function Conditions() {
  const { pid } = Route.useParams();

  return (
    <ValidationLayout resourceType="CONDITION" pid={pid}>
      <ConditionsView pid={pid} />
    </ValidationLayout>
  );
}
